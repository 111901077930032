
html, body {
  height: 100%;
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

/* html elements */
textarea {
  height: 100%;
  width: 100%;
  border: 5px solid black;
  font-size: large;
  padding: 5px;
  margin: 10px;
}
textarea:disabled {
  border: 5px solid grey;
}
button {
  width: 100%;
  border: 2px solid black;
  border-radius: 5px;
  font-size: large;
  padding: 5px;
  margin: 10px;
}
select {
  width: 100%;
  height: 100%;
  border: 2px solid black;
  border-radius: 5px;
  font-size: large;
  padding: 5px;
  margin: 10px;
}

/* classes */
.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100%;
  padding: 10px;
}
.half-width {
  height: 100%;
  margin: 5px;
  width: 50%;
  padding: 10px;
}
.box {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: right;
  padding: 15px;
}

/* loader */
.loaderContainer {
  z-index: 10000;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: absolute;
  overflow: hidden;
  width: 100%;
}
.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid grey; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}